footer {
    background-color: $black;
    @include adaptiv-vaule("padding-top", 55, 30, 1);
    @include adaptiv-vaule("padding-bottom", 55, 30, 1);

    .footer_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer_text {
        color: #D0D0D0;
        @include adaptiv-vaule("font-size", 16, 14, 1);
        font-weight: 400;
        line-height: normal;
    }
}
