header {
    background-color: $black;
    border-bottom: 1px solid $white;

    @include media ("<tablet") {
        height: 60px;
        border: none;
        display: flex;
        align-items: center;
        padding: 5px;
    }

    .header_content {
        @include media ("<tablet") {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .nav {
        @include media ("<tablet") {
            position: fixed;
            top: 0;
            right: -100%;
            height: 100vh;
            width: 70%;
            background-color: $black;
            transition: all .3s ease;
            padding: 100px 50px 30px 50px;
            overflow: auto;
        }

        @include media ("<phone") {
            width: 100%;
        }
    }

    .menu_active {
        right: 0;
    }

    .nav_menu {
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0;

        @include media ("<tablet") {
            height: auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 20px
        }
    }

    .menu_item {
        @include adaptiv-vaule("padding-top", 35, 15, 1);
        @include adaptiv-vaule("padding-bottom", 35, 15, 1);
        height: 100%;
        position: relative;

        @include media ("<tablet") {
            height: auto;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid $white;
            width: 100%;
        }

        &:not(:last-child) {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                @include adaptiv-vaule("right", -80, 20, 1);
                height: 100%;
                width: 1px;
                background-color: $white;

                @include media ("<tablet") {
                    display: none;
                }
            }
        }
    }

    .menu_link {
        color: $white;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: -0.6px;
    }

    .nav_btn {
        display: none;
        color: $white;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.6px;
        text-transform: uppercase;
        text-align: center;
        border: 1px solid $white;
        width: 100%;
        padding: 25px;

        @include media ("<tablet") {
            display: block;
        }
    }

    .header_burger {
        display: none;
        width: 32px;
        height: 23px;
        position: relative;
        cursor: pointer;
        z-index: 3;

        @include media ("<tablet") {
            display: block;
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background: $white;
            transition: all 0.4s ease;
        }

        span {
            position: absolute;
            width: 100%;
            height: 2px;
            background: $white;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            transition: all 0.4s ease;
            opacity: 1;
        }

        &::after {
            bottom: 0;
        }

        &::before {
            top: 0;
        }
    }

    .burger_active {
        &::after {
            transform: rotate(-45deg);
            bottom: 9px;
        }

        &::before {
            transform: rotate(45deg);
            top: 11px;
            ;
        }

        span {
            opacity: 0;
            visibility: hidden;
        }
    }
}