body {
    background-color: $white;
}

html{
    scroll-behavior: smooth;
}

.container {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.main_section {
    background-color: $black;
    @include adaptiv-vaule("padding-top", 80, 30, 1);
    @include adaptiv-vaule("padding-bottom", 170, 60, 1);

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        @include adaptiv-vaule("margin-bottom", 50, 20, 1);

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .block_text {
        width: 55%;
        margin-right: 30px;

        @include media ("<desktop") {
            width: 100%;
            margin-right: 0;
        }
    }

    .main_title {
        color: $white;
        @include adaptiv-vaule("font-size", 60, 32, 1);
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 35px;
    }

    .main_descr {
        color: $white;
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 300;
        line-height: 120%;
        letter-spacing: -0.6px;
        @include adaptiv-vaule("margin-bottom", 50, 30, 1);
    }

    .main_btn {
        display: inline-block;
        color: $white;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.6px;
        text-transform: uppercase;
        text-align: center;
        border: 1px solid $white;
        width: 100%;
        padding: 25px;
        max-width: 330px;
        width: 100%;
        transition: all .3s ease;

        &:hover {
            background-color: $white;
            color: $black;
            transition: all .3s ease;
        }
    }

    .block_img {
        max-width: 450px;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .name {
        width: 100%;
        color: #FFF;
        text-align: right;
        @include adaptiv-vaule("font-size", 24, 20, 1);
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.72px;
        text-transform: uppercase;
    }
}

.biography {
    @include adaptiv-vaule("padding-top", 105, 85, 1);
    @include adaptiv-vaule("padding-bottom", 105, 55, 1);
    border-bottom: 1px solid $black;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 100%;
        background-color: $black;

        @include media ("<tablet") {
            display: none;
        }
    }

    .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .text_block_one,
    .text_block_two {
        width: 40%;

        @include media ("<tablet") {
            width: 100%;
        }
    }

    .text_block_one {
        @include media ("<tablet") {
            position: relative;
            padding-bottom: 50px;
            margin-bottom: 50px;

            &::after {
                content: '';
                position: absolute;
                left: -20px;
                bottom: 0;
                width: 110%;
                height: 1px;
                background-color: $black;
            }
        }

        .title {
            color: $black;
            @include adaptiv-vaule("font-size", 48, 32, 1);
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -1.44px;
            text-transform: uppercase;
            margin-bottom: 40px;
        }

        .descr {
            color: $black;
            @include adaptiv-vaule("font-size", 20, 16, 1);
            font-weight: 300;
            line-height: 120%;
            letter-spacing: -0.6px;
        }
    }

    .text_block_two {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .title {
            font-size: 32px;
            @include adaptiv-vaule("font-size", 32, 24, 1);
            font-weight: 700;
            line-height: 120%;
        }

        .bold_text {
            display: inline-block;
            @include adaptiv-vaule("font-size", 20, 16, 1);
            color: $black;
            font-weight: 500;
            line-height: 200%;
            letter-spacing: -0.6px;
        }

        .descr {
            color: $black;
            @include adaptiv-vaule("font-size", 20, 16, 1);
            font-weight: 400;
            letter-spacing: -0.6px;

            .bold {
                font-weight: 500;
            }
        }
    }
}

.profession {
    @include adaptiv-vaule("padding-top", 85, 80, 1);
    @include adaptiv-vaule("padding-bottom", 70, 55, 1);
    border-bottom: 1px solid $black;

    .title {
        text-align: right;
        @include adaptiv-vaule("font-size", 48, 32, 1);
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        @include adaptiv-vaule("margin-bottom", 70, 55, 1);
    }

    .text_wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .left_column {
        width: 45%;

        @include media ("<desktop") {
            width: 49%;
        }

        @include media ("<tablet") {
            width: 100%;
        }

        .text_block {
            &:not(:last-child) {
                margin-bottom: 60px;
            }
        }

        .text_title {
            @include adaptiv-vaule("font-size", 32, 24, 1);
            font-weight: 600;
            line-height: 120%;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        .descr {
            @include adaptiv-vaule("font-size", 20, 16, 1);
            color: $black;
            font-weight: 300;
            line-height: 120%;
            letter-spacing: -0.6px;
            max-width: 500px;
            width: 100%;
        }
    }

    .right_column {
        width: 36%;

        @include media ("<desktop") {
            width: 45%;
        }

        @include media ("<tablet") {
            width: 100%;
        }

        .text_title {
            @include adaptiv-vaule("font-size", 32, 24, 1);
            font-weight: 600;
            line-height: 120%;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        .list_wrap {
            padding: 0;
            list-style: none;
        }

        .list_item,
        .tip {
            color: $black;
            @include adaptiv-vaule("font-size", 20, 16, 1);
            font-weight: 300;
            line-height: 120%;
            letter-spacing: -0.6px;
        }
    }
}

.donations {
    @include adaptiv-vaule("padding-top", 75, 85, 1);
    @include adaptiv-vaule("padding-bottom", 180, 60, 1);
    border-bottom: 1px solid $black;

    .title {
        @include adaptiv-vaule("font-size", 48, 32, 1);
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        @include adaptiv-vaule("margin-bottom", 130, 55, 1);
        max-width: 520px;
    }

    .text_wrap {
        display: grid;
        grid-template: repeat(3, 1fr) / repeat(3, 1fr);
        justify-content: space-between;

        @include media ("<tablet") {
            grid-template: 1fr / 1fr;
        }
    }

    .block_text {
        color: $black;
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 300;
        line-height: 120%;
        letter-spacing: -0.6px;
        padding: 40px;
        @include adaptiv-vaule("padding-top", 40, 15, 1);
        @include adaptiv-vaule("padding-bottom", 40, 15, 1);
        @include adaptiv-vaule("padding-right", 40, 15, 1);
        @include adaptiv-vaule("padding-left", 40, 15, 1);

        &:nth-child(1) {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;

            @include media ("<tablet") {
                border-right: none;
            }
        }

        &:nth-child(2) {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;

            @include media ("<tablet") {
                border-right: none;
            }
        }

        &:nth-child(3) {
            border-bottom: 1px solid $black;
        }

        &:nth-child(4) {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;

            @include media ("<tablet") {
                border-right: none;
            }
        }

        &:nth-child(5) {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;

            @include media ("<tablet") {
                border-right: none;
            }
        }

        &:nth-child(6) {
            border-bottom: 1px solid $black;
        }

        &:nth-child(7) {
            border-right: 1px solid $black;

            @include media ("<tablet") {
                border-right: none;
                border-bottom: 1px solid $black;
            }
        }

        &:nth-child(8) {
            border-right: 1px solid $black;

            @include media ("<tablet") {
                border-right: none;
                border-bottom: 1px solid $black;
            }
        }
    }
}

.loans {
    @include adaptiv-vaule("padding-top", 70, 85, 1);
    @include adaptiv-vaule("padding-bottom", 45, 85, 1);

    .title {
        color: $black;
        text-align: right;
        @include adaptiv-vaule("font-size", 48, 32, 1);
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        max-width: 745px;
        width: 100%;
        margin: 0 0 0 auto;
        @include adaptiv-vaule("margin-bottom", 65, 55, 1);
    }

    .text_wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 25px;
    }

    .text_block {
        max-width: 220px;
        width: 100%;
        color: $black;
        font-size: 20px;
        font-weight: 300;
        line-height: 120%;
        letter-spacing: -0.6px;

        @include media ("<phone") {
            max-width: 300px;
        }
    }
}

.extension {
    @include adaptiv-vaule("padding-top", 95, 90, 1);
    background-color: $black;
    color: $white;
    overflow: hidden;

    .title {
        color: $white;
        text-align: right;
        @include adaptiv-vaule("font-size", 48, 32, 1);
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        max-width: 780px;
        margin: 0 0 0 auto;
        @include adaptiv-vaule("margin-bottom", 130, 80, 1);
    }

    .text_wrap_top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include adaptiv-vaule("padding-top", 115, 60, 1);
        @include adaptiv-vaule("padding-bottom", 160, 60, 1);
        position: relative;

        @include media ("<desktop") {
            flex-direction: column;
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            width: 130%;
            height: 1px;
            background-color: $white;
        }

        &::before {
            top: 0;
            left: -10%;
        }

        &::after {
            bottom: 0;
            left: -10%;
        }

        .middle_line {
            position: absolute;
            background-color: $white;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: 100%;

            @include media ("<desktop") {
                display: none;
            }
        }

        .text_block {
            width: 40%;

            @include media ("<desktop") {
                width: 100%;
            }
        }
    }

    .block_title {
        color: $white;
        @include adaptiv-vaule("font-size", 32, 24, 1);
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .block_text {
        color: $white;
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.6px;
    }

    .text_wrap_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include adaptiv-vaule("padding-top", 115, 60, 1);
        @include adaptiv-vaule("padding-bottom", 160, 60, 1);
        position: relative;
        gap: 50px;

        @include media ("<desktop") {
            flex-direction: column;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 44%;
            width: 1px;
            height: 100%;
            background-color: $white;

            @include media ("<desktop") {
                display: none;
            }
        }

        .block_text_wrap {
            width: 55%;

            @include media ("<desktop") {
                width: 100%;
            }
        }

        .text_wrap {
            &:first-child {
                margin-bottom: 50px;
            }
        }

        .block_img {
            max-width: 430px;
        }
    }
}

.museum {
    @include adaptiv-vaule("padding-top", 110, 85, 1);
    @include adaptiv-vaule("padding-bottom", 130, 85, 1);
    overflow: hidden;

    .title {
        color: $black;
        text-align: right;
        @include adaptiv-vaule("font-size", 48, 32, 1);
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        @include adaptiv-vaule("padding-bottom", 40, 55, 1);
        @include adaptiv-vaule("margin-bottom", 65, 30, 1);
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -10%;
            width: 110%;
            height: 1px;
            background-color: $black;
        }
    }

    .block_text {
        position: relative;
        @include adaptiv-vaule("padding-bottom", 40, 55, 1);
        @include adaptiv-vaule("margin-bottom", 50, 30, 1);
        width: 100%;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -10%;
            width: 110%;
            height: 1px;
            background-color: $black;
        }
    }

    .sub_title {
        color: $black;
        @include adaptiv-vaule("font-size", 32, 24, 1);
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        margin-bottom: 15px;
        max-width: 630px;
        width: 100%;
    }

    .sub_text {
        position: relative;
        @include adaptiv-vaule("padding-bottom", 50, 30, 1);
        @include adaptiv-vaule("margin-bottom", 30, 30, 1);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -10%;
            width: 110%;
            height: 1px;
            background-color: $black;
        }
    }

    .text {
        color: $black;
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
        letter-spacing: 1px;
        max-width: 630px;
        width: 100%;
    }
}

.quote {
    @include adaptiv-vaule("padding-top", 110, 85, 1);
    @include adaptiv-vaule("padding-bottom", 130, 85, 1);
    background-color: $black;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 20%;
        width: 1px;
        height: 100%;
        background-color: $white;

        @include media ("<tablet") {
            display: none;
        }
    }

    .content {
        max-width: 900px;
        width: 100%;
        margin: 0 0 0 auto;
    }

    .text {
        color: $white;
        text-align: right;
        @include adaptiv-vaule("font-size", 32, 24, 1);
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.96px;
        text-transform: uppercase;
    }

    .quote_text {
        margin-bottom: 35px;
    }
}


.contact {
    @include adaptiv-vaule("padding-top", 130, 85, 1);
    @include adaptiv-vaule("padding-bottom", 180, 50, 1);

    .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .title {
        width: 30%;
        color: $black;
        @include adaptiv-vaule("font-size", 48, 32, 1);
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        margin: 0;

        @include media ("<tablet") {
            margin-bottom: 35px;
        }
    }

    form {
        max-width: 390px;
        width: 100%;
    }

    .input_wrap {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;
    }

    .data_name {
        color: #000;
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .data_text,
    .area_text {
        width: 100%;
        border: none;
        border-bottom: 1px solid $black;
        padding: 12px 0;
        color: $black;
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 300;
        line-height: 120%;
        letter-spacing: -0.6px;

        &::placeholder{
            color: #909090;
        }
    }

    .area_text {
        height: 150px;
    }

    .submit_btn {
        border: none;
        background-color: $white;
        border: 1px solid $black;
        display: inline-block;
        width: 100%;
        @include adaptiv-vaule("padding-top", 30, 25, 1);
        @include adaptiv-vaule("padding-bottom", 30, 25, 1);
        transition: all .3s ease;
        color: $black;
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.6px;
        text-transform: uppercase;

        &:hover {
            background-color: $black;
            color: $white;
            transition: all .3s ease;
        }
    }
}